<template>
  <FormContent :selected="selected" class="pa-6">
    <div class="box--book color--grey-0">
      Acompanhe ou defina as configurações das faturas das nossas plataformas de venda.
    </div>
    <div class="custom-font-title my-5">
      Sobre o seller
    </div>
    <div class="d-flex justify-space-between ">
      <div class="d-flex itens">
        <label class="itens-description">Nome fantasia</label>
        <v-text-field v-model="form.seller.companyName" :readonly="true" filled dense light />
      </div>
      <div class="d-flex itens">
        <label class="mx-5">CNPJ</label>
        <v-text-field v-model="form.seller.cnpj" :readonly="true" filled dense light />
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <div class="itens">
        <div class="custom-font-title mb-5 mt-8">Fechamento das faturas</div>
        <div class="d-flex">
          <label class="itens-description">Dia limite*</label>
          <div class="itens-object">
            <v-text-field
              v-model="form.closingDay"
              :disabled="!isAdministrator"
              type="number"
              :rules="[
                (value) => !!value || 'Campo obrigatório',
                (value) => validateTheNumber(value, 'Dia limite'),
                (value) => validateTheDayOfMonth(value, 'Dia limite'),
              ]"
              placeHolder="Digite ou selecione o dia"
              validate-on-blur
              filled
              dense
              light
            />
          </div>
        </div>
      </div>
      <div class="itens">
        <div class="custom-font-title mx-5 mb-5 mt-8">Pagamento dos valores das faturas</div>
        <div class="d-flex">
          <label class="mx-5">Dia de disponibilidade*</label>
          <div class="itens-object">
            <v-text-field
              v-model="form.recurringDays"
              :disabled="!isAdministrator"
              type="number"
              :rules="[
                (value) => !!value || 'Campo obrigatório',
                (value) => validateTheNumber(value, 'Dia de disponibilidade'),
                (value) => validateTheDayOfMonth(value, 'Dia de disponibilidade'),
              ]"
              placeHolder="Digite ou selecione o dia"
              validate-on-blur
              filled
              dense
              light
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-space-between">
      <div class="itens">
        <div class="custom-font-title  mb-5 mt-8">Provisionamento dos valores</div>
        <div class="d-flex">
          <label class="itens-description">Dias de carência*</label>
          <div class="itens-object">
            <v-text-field
              v-model="form.gracePeriodDay"
              :disabled="!isAdministrator"
              type="number"
              :rules="[
                (value) => validateTheNumber(value, 'Dias de carência'),
                (value) => value >= 0 || 'Campo `Dias de carência` não pode ser negativo.',
                (value) => value <= 30 || 'Campo `Dias de carência` não pode ser maior que 30.',
              ]"
              placeHolder="Digite ou selecione o dia"
              validate-on-blur
              filled
              dense
              light
            />
          </div>
        </div>
      </div>
      <div class="itens">
        <div class="d-flex"></div>
      </div>
    </div>
  </FormContent>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';

export default {
  name: 'ConfigForm',
  components: {
    FormContent,
  },
  props: {
    form: { type: Object, default: () => ({}) },
    mask: { type: Object, default: () => ({}) },
    isAdministrator: { type: Boolean, default: false },
  },
  data: () => ({
    selected: false,
  }),
  methods: {
    validateTheDayOfMonth(value, field) {
      const validationRules = [
        { condition: value < 0, message: `O campo '${field}' não pode ser negativo.` },
        { condition: value === '0', message: `O campo '${field}' não pode ser zero.` },
        { condition: value > 31, message: `O campo '${field}' não pode ser maior que 31.` },
      ];
      const error = validationRules.find((rule) => rule.condition);
      return error ? error.message : true;
    },
    validateTheNumber(value, field) {
      return value !== '' || `Valor inválido para o campo '${field}'.`;
    },
  },
};
</script>

<style lang="scss">
.label-color-config {
  color: gray;
}
.label-invoiceConfig-title {
  font-size: 18px;
}
.label-invoiceConfig-subtitle {
  font-size: 15px;
}

.itens {
  width: 50%;
  &-description {
    width: 25%;
  }
  &-object {
    width: 300px;
  }
}
</style>
