<template>
  <v-container fill-height>
    <v-row class='d-flex justify-start align-start pa-1 mb-1'
          style='height: 100%; width: 100%;'>
      <v-col cols='12' lg='12' xl='12'>
        <v-row class='margin-fix pa-2 pl-11'>
          <h2>{{title}}</h2>
        </v-row>
        <v-form class='background-default' ref="form" v-model="isValid" lazy-validation>
          <slot></slot>
        </v-form>
      </v-col>
    </v-row>
    <v-row class='justify-end'>
          <div class='d-flex justify-space-between mt-3'>
        <span>
          <slot v-if="canSubbmit()" name="action"></slot>
          <v-btn
              v-if='showActionButtons'
            depressed
            large
              color='#07BEE7'
              class='mr-4 form_step_controller_action_button'
              @click='cancelForm()'
              outlined>
            CANCELAR
          </v-btn>
          <v-btn
              v-if='showActionButtons'
            depressed
            large
              color='#07BEE7'
              class='form_step_controller_action_button'
              @click='subbmitForm()'
            :disabled="(!canSubbmit() && !canSubbmitEspecificatio) || blockSave || !isValid"
              outlined>
            SALVAR
          </v-btn>
        </span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { routerBack } from '@/service/kplaceRouter';

export default Vue.extend({
  components: {
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    canSubbmitEspecificatio: {
      type: Boolean,
      default: false,
    },
    blockSave: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
    },
    steps: {
      type: Object,
      default: () => ({}),
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hide() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        case 'sm': return true;
        case 'md': return false;
        case 'lg': return false;
        case 'xl': return false;
        default: return false;
      }
    },
  },
  data() {
    return {
      tabs: [],
      currentTab: 0,
      totalTabs: 0,
      backDialogOpen: false,
      isValid: true,
    };
  },
  mounted() {
    let count = 0;
    this.tabs = this.$slots.default;
    this.totalTabs = this.tabs.length;

    this.tabs.forEach((tab) => {
      if ((tab.componentInstance).selected === true) {
        this.currentTab = count;
      }
      count += 1;
    });

    // Select first tab if none is marked selected
    if (this.currentTab === -1 && this.totalTabs > 0) {
      (this.tabs[0].componentInstance).selected = true;
      this.currentTab = 0;
    }
    this.switchTab(this.currentTab);
  },
  methods: {
    openBackDialog() {
      this.backDialogOpen = true;
    },
    closeBackDialog() {
      this.backDialogOpen = false;
    },
    exitForm() {
      this.backDialogOpen = false;
      routerBack();
    },
    switchTab(index) {
      const arStepKeys = Object.keys(this.steps);
      let count = 0;
      this.tabs.forEach((tab) => {
        let icon = 'check';
        let color = 'green';
        let isOpenned = false;
        if (arStepKeys.length > 0) {
          icon = arStepKeys.length > count && !this.steps[arStepKeys[count]].status.valid ? 'alert' : 'check';
          color = arStepKeys.length > count && !this.steps[arStepKeys[count]].status.valid ? 'orange' : 'green';
          this.steps[arStepKeys[count]].status.oppened = this.steps[arStepKeys[count]].step - 1 < index
              || this.steps[arStepKeys[count]].status.oppened;
          isOpenned = this.steps[arStepKeys[count]].status.oppened
              || this.steps[arStepKeys[count]].step - 1 < index;
          this.steps[arStepKeys[index]].status.oppened = true;
        }
        (tab.componentInstance).selected = false;
        count += 1;
      });

      this.currentTab = index;
      (this.tabs[index].componentInstance).selected = true;
    },
    subbmitForm() {
      const formIsValid = this.$refs.form.validate();
      if (!formIsValid) {
        return;
      }
      this.$emit('formSubbmit');
    },
    cancelForm() {
      this.$emit('formCancel');
    },
    canSubbmit() {
      const arStepKeys = Object.keys(this.steps);
      for (let i = 0; i < arStepKeys.length; i += 1) {
        if (!this.steps[arStepKeys[i]].status.valid) {
          return false;
        }
      }
      return true;
    },
  },
});

</script>

<style scoped>

.background-default {
  background-color: #f8f8f8;
}

.form-step-controller-title {
  text-align: left;
  font: normal normal bold 22px/24px Gotham Rounded, sans-serif;
  letter-spacing: 0px;
  color: #190804;
  opacity: 1;
  min-width: 200px;
}

.form_step_controller_action_button {
  text-align: right;
  font: normal normal medium 15px/18px Gotham Rounded, sans-serif;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
}

</style>
