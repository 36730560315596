var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormContent',{staticClass:"pa-6",attrs:{"selected":_vm.selected}},[_c('div',{staticClass:"box--book color--grey-0"},[_vm._v(" Acompanhe ou defina as configurações das faturas das nossas plataformas de venda. ")]),_c('div',{staticClass:"custom-font-title my-5"},[_vm._v(" Sobre o seller ")]),_c('div',{staticClass:"d-flex justify-space-between "},[_c('div',{staticClass:"d-flex itens"},[_c('label',{staticClass:"itens-description"},[_vm._v("Nome fantasia")]),_c('v-text-field',{attrs:{"readonly":true,"filled":"","dense":"","light":""},model:{value:(_vm.form.seller.companyName),callback:function ($$v) {_vm.$set(_vm.form.seller, "companyName", $$v)},expression:"form.seller.companyName"}})],1),_c('div',{staticClass:"d-flex itens"},[_c('label',{staticClass:"mx-5"},[_vm._v("CNPJ")]),_c('v-text-field',{attrs:{"readonly":true,"filled":"","dense":"","light":""},model:{value:(_vm.form.seller.cnpj),callback:function ($$v) {_vm.$set(_vm.form.seller, "cnpj", $$v)},expression:"form.seller.cnpj"}})],1)]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"itens"},[_c('div',{staticClass:"custom-font-title mb-5 mt-8"},[_vm._v("Fechamento das faturas")]),_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"itens-description"},[_vm._v("Dia limite*")]),_c('div',{staticClass:"itens-object"},[_c('v-text-field',{attrs:{"disabled":!_vm.isAdministrator,"type":"number","rules":[
              function (value) { return !!value || 'Campo obrigatório'; },
              function (value) { return _vm.validateTheNumber(value, 'Dia limite'); },
              function (value) { return _vm.validateTheDayOfMonth(value, 'Dia limite'); } ],"placeHolder":"Digite ou selecione o dia","validate-on-blur":"","filled":"","dense":"","light":""},model:{value:(_vm.form.closingDay),callback:function ($$v) {_vm.$set(_vm.form, "closingDay", $$v)},expression:"form.closingDay"}})],1)])]),_c('div',{staticClass:"itens"},[_c('div',{staticClass:"custom-font-title mx-5 mb-5 mt-8"},[_vm._v("Pagamento dos valores das faturas")]),_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"mx-5"},[_vm._v("Dia de disponibilidade*")]),_c('div',{staticClass:"itens-object"},[_c('v-text-field',{attrs:{"disabled":!_vm.isAdministrator,"type":"number","rules":[
              function (value) { return !!value || 'Campo obrigatório'; },
              function (value) { return _vm.validateTheNumber(value, 'Dia de disponibilidade'); },
              function (value) { return _vm.validateTheDayOfMonth(value, 'Dia de disponibilidade'); } ],"placeHolder":"Digite ou selecione o dia","validate-on-blur":"","filled":"","dense":"","light":""},model:{value:(_vm.form.recurringDays),callback:function ($$v) {_vm.$set(_vm.form, "recurringDays", $$v)},expression:"form.recurringDays"}})],1)])])]),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"itens"},[_c('div',{staticClass:"custom-font-title  mb-5 mt-8"},[_vm._v("Provisionamento dos valores")]),_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"itens-description"},[_vm._v("Dias de carência*")]),_c('div',{staticClass:"itens-object"},[_c('v-text-field',{attrs:{"disabled":!_vm.isAdministrator,"type":"number","rules":[
              function (value) { return _vm.validateTheNumber(value, 'Dias de carência'); },
              function (value) { return value >= 0 || 'Campo `Dias de carência` não pode ser negativo.'; },
              function (value) { return value <= 30 || 'Campo `Dias de carência` não pode ser maior que 30.'; } ],"placeHolder":"Digite ou selecione o dia","validate-on-blur":"","filled":"","dense":"","light":""},model:{value:(_vm.form.gracePeriodDay),callback:function ($$v) {_vm.$set(_vm.form, "gracePeriodDay", $$v)},expression:"form.gracePeriodDay"}})],1)])]),_c('div',{staticClass:"itens"},[_c('div',{staticClass:"d-flex"})])])])}
var staticRenderFns = []

export { render, staticRenderFns }