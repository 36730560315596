<template>
    <div v-show="selected">
        <slot></slot>
    </div>
</template>

<script>

export default {

  props: {
    selected: { type: Boolean, require: false, default: false },
  },
};

</script>
